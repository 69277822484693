@import "../../assets/variables";
@import "../../assets/mixins";

$item-gap: 0.25rem;
$item-height: $radio-size;

.radios {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;

  &__item {
    margin-bottom: 0.5rem;
  }
}

.radioItem {
  position: relative;
  font-weight: 500;

  & > label {
    display: flex;
    align-items: flex-start;
    line-height: 1.25;
    @include transitions();

    & > div {
      display: grid;
    }
    span {
      margin-top: calc((#{$item-height} - #{$font-size-base}) / 2);
      cursor: default;
    }

    &::before {
      content: "";
      display: block;
      flex-shrink: 0;
      margin-right: 0.5rem;
      background-color: $casio-light-grey;
      height: $item-height;
      width: $item-height;
      border: 1px solid transparent;
      border-radius: 50%;
      @include transitions();
    }

    &::after {
      position: absolute;
      left: calc((#{$item-height} - 15px) / 2);
      top: calc((#{$item-height} - 15px) / 2);
      width: 15px;
      height: 15px;
      content: "";
      display: block;
      flex-shrink: 0;
      margin-right: 0.5rem;
      background-color: $casio-blue-2;
      border: 1px solid transparent;
      border-radius: 50%;
      opacity: 0;
      transform: scale(0.5);
      @include transitions();
    }
  }

  input[type="radio"] {
    width: $item-height;
    height: $item-height;
    position: absolute;
    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:checked + label {
      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &:hover {
    label {
      &::before {
        background-color: $casio-blue-5;
      }
    }
  }

  &:focus-within {
    label::before {
      border-color: $casio-blue-2;
      background-color: $casio-blue-5;
    }
  }
}

.radioItem_light {
  label {
    font-weight: 600;
    color: $casio-blue-2;

    &::before {
      background-color: $casio-white;
      border-color: $casio-light-grey;
    }

    &::before,
    &::after {
      margin-right: 1rem;
    }
  }

  &:hover {
    label {
      &::before {
        background-color: $casio-white;
        border-color: $casio-blue-2;
      }
    }
  }
  &:focus-within {
    label::before {
      background-color: $casio-white;
    }
  }
}
