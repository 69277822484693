@import "../../assets/variables";
@import "../../assets/mixins";

.info {
  margin-bottom: 0.5rem;
}

.verification_subline {
  color: $casio-dark-grey;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.email_verification {
  border-left: 0.25rem solid;
  border-color: $casio-light-grey;
  padding-left: 0.75rem;
  margin-bottom: 0.5rem;

  &__header {
    display: flex;
    justify-content: space-between;
    color: $casio-dark-grey;
    margin-bottom: 0.25rem;
    &__close {
      width: 2rem;
      height: 2rem;
      color: $casio-dark-grey;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transitions();

      &:hover {
        color: $casio-black;
      }
    }
  }

  &__info {
    color: $casio-dark-grey;
    font-size: $font-size-s;
  }
}
