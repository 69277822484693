/*
 * Colors
 */

$casio-blue: #092981;
$casio-blue-1: $casio-blue;
$casio-blue-2: #3957a8;
$casio-blue-3: #758bc5;
$casio-blue-4: #d1d8ee;
$casio-blue-5: #eff2fa;

$casio-black: #1a1a1a;
$casio-dark-grey: #808080;
$casio-light-grey: #eeeeee;
$casio-lighter-grey: #f6f6f6;
$casio-white: #ffffff;
$casio-white-dimmed: #fafafa;

$classpad-red: #e01044;
$classpad-red-4: #fadbe3;
$classpad-red-5: #fdf3f6;

$classpad-cyan: #c7fcff;
$classpad-blue: #bcdaff;
$classpad-purple: #e3d6ff;
$classpad-pale-red: #ffe1ee;
$classpad-magenta: #ffb6e2;
$classpad-pale-orange: #fff4f1;
$classpad-orange: #ffb09e;
$classpad-yellow: #fff09f;
$classpad-green: #9cfbc2;
$classpad-brown: #e7d1c2;

$classpad-cyan-dark: #9adfe2;
$classpad-blue-dark: #8fbbf0;
$classpad-purple-dark: #c1afe9;
$classpad-pale-red-dark: #febfda;
$classpad-magenta-dark: #f893cf;
$classpad-orange-dark: #f58d77;
$classpad-yellow-dark: #ecd76c;
$classpad-green-dark: #64d892;
$classpad-brown-dark: #d0b19c;
$classpad-green-darker: #59bf81;

$mm-background: #fae5d3;
$mm-background-dimmed: #f6d7bc;
$mm-background-dark: #ddc1a9;

/*
 * Fonts
 */

$font-sans: "Noto Sans", sans-serif;

$font-size-root: 20px;

$font-size-xxs: 0.5rem;
$font-size-xs: 0.6rem;
$font-size-s: 0.75rem;
$font-size-base: 1rem;
$font-size-m: clamp(1.25rem, 2.5vw, 1.35rem);
$font-size-l: clamp(1.5rem, 2.5vw, 1.35rem);
$font-size-xl: clamp(2rem, 3vw, 2.4rem);
$font-size-xxl: clamp(2.2rem, 5vw, 3.2rem);

/*
 * Attributes
 */

$border-radius-base: 3px;
$line-height-default: 1.5;
$line-height-heading: 1.125;

/*
 * Dimensions
 */

$icon-size-base: 24px;
$icon-size-l: 32px;

$radio-size: 35px;

$header-height: 46px;
$content-max-width: 860px;

/*
 * Break-Points
 */

$break-point-sm: 576px;
$break-point-md: 768px;
$break-point-lg: 992px;
$break-point-xl: 1120px;
$break-point-xxl: 1440px;
