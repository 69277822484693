@import "../../assets/variables";
@import "../../assets/mixins";

$duration: $transition-duration;

@mixin title($textColor: black) {
  margin: 0;
  font-size: $font-size-l;
  color: $textColor;
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  &__backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &_loading > .modal__dialog {
    opacity: 0;
    transform: translateY(100%);
  }

  &__dialog {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity $duration $transition-timing,
      transform $duration $transition-timing;

    width: 100%;
    margin: 0 0.5rem;
    position: relative;
    z-index: 10;
    background-color: $casio-white;
    border-radius: $border-radius-base;

    &:focus {
      outline: none;
    }

    &__padded {
      padding: 1rem;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;

      &__padded {
        padding: 1rem 1rem 0 1rem;
      }

      &__title {
        @include title();
      }

      &__title_error {
        @include title($classpad-red);
      }

      &__close {
        width: 2rem;
        height: 2rem;
        background-color: $casio-blue-5;
        color: $casio-blue-2;
        padding: 0;
        border-radius: $border-radius-base;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        @include transitions();

        &:hover {
          background-color: $casio-blue-2;
          color: $casio-white;
        }
      }
    }

    &__body {
      max-height: 75vh;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }
  }
}

@include mq-sm-min {
  .modal__dialog {
    width: 80%;
    max-width: $content-max-width * 0.5;
    margin: 0;

    &_superlarge {
      max-width: $content-max-width * 1.6;
    }
    &_large {
      max-width: $content-max-width;
    }
    &_medium {
      max-width: $content-max-width * 0.6;
    }
  }
}

.transition {
  &_appear,
  &_enter {
    > .modal__backdrop {
      opacity: 1;
    }
    &:not(.modal_loading) > .modal__dialog {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &_appearDone,
  &_enterDone {
    > .modal__backdrop {
      opacity: 1;
      transition: opacity $duration $transition-timing;
    }
    &:not(.modal_loading) > .modal__dialog {
      opacity: 1;
      transform: translateY(0);
      transition: opacity $duration $transition-timing,
        transform $duration $transition-timing;
    }
  }

  &_exit {
    > .modal__backdrop {
      opacity: 1;
      transition: opacity $duration $transition-timing;
    }
    &:not(.modal_loading) > .modal__dialog {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &_exitActive {
    > .modal__backdrop {
      opacity: 0;
      transition: opacity $duration $transition-timing;
    }
    &:not(.modal_loading) > .modal__dialog {
      opacity: 0;
      transform: translateY(100%);
      transition: opacity $duration $transition-timing,
        transform $duration $transition-timing;
    }
  }
}
