@import "../../assets/variables";
@import "../../assets/mixins";

.registerForm {
  &__errors {
    width: 100%;
    color: white;
    font-size: $font-size-s;
    font-weight: 500;
    line-height: 1.5;
    background-color: $classpad-red;
    border-radius: $border-radius-base;
    margin-bottom: 1.5rem;
    padding: 0.5rem;
  }

  &__hidden {
    visibility: hidden;
  }

  &__privacyPolicy {
    margin-bottom: 1rem;
  }

  &__termsAndConditions {
    margin: 1rem 0 1rem 0;
  }

  &__agreement {
    margin-bottom: 1rem;
    font-size: large;
  }

  &__teacherInfoService {
    margin-top: 2rem;

    &__info {
      font-size: $font-size-s;
      font-weight: 400;
    }

    &__boxes {
      margin: 1rem 0 1rem 0;
      font-weight: 500;

      & .indented {
        padding-top: 1rem;

        @include mq-sm-min {
          padding-left: 2rem;
        }
      }
    }

    &__extra {
      margin-bottom: 1rem;
    }
  }
}
