@import "../../assets/variables";
@import "../../assets/mixins";

.card {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: $border-radius-base;
  background-color: $casio-white;
  @include box-shadow;

  > h2 {
    font-size: $font-size-l;
    margin-top: 0;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    i {
      display: flex;
      align-items: center;
      width: $font-size-l;
      height: $font-size-l;
      margin-right: 1rem;

      svg {
        stroke-width: 2.5;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.card_small {
  > h2 {
    font-size: $font-size-m;
    margin-top: 0;
    margin-bottom: 1rem;

    i {
      display: flex;
      align-items: center;
      width: $font-size-m;
      height: $font-size-m;
      margin-right: 0.75rem;

      svg {
        stroke-width: 2.5;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.card_matheMarathon {
  background-color: $mm-background;

  &.card_clickable {
    &:hover {
      color: $casio-black;
      background-color: $mm-background;
    }
  }
}

.card_clickable {
  cursor: pointer;
  transform: scale(1);
  @include transitions;

  &:hover {
    background-color: $casio-blue-2;
    color: $casio-white;
    transform: scale(1.02);
  }
}

.card_cplTestPeriod {
  background-color: $classpad-green-dark;
  color: $casio-white;

  &:hover {
    background-color: $classpad-green-darker;
  }
}

@include mq-md-min {
  .card {
    padding: 1.5rem;
  }
}

@include mq-sm-min {
  .card_topicOverview {
    padding: 0.5rem;
  }
}
