@import "../../assets/variables";
@import "../../assets/mixins";

.badge {
  background-color: $classpad-green-dark;
  margin-right: 1rem;
  white-space: nowrap;
  color: $casio-white;
  display: inline-block;
  padding: 0.5rem 1rem;
  font-weight: 600;
  width: 12rem;
  text-align: center;
  border-radius: 50px;

  &_size_small {
    font-size: $font-size-s;
    width: auto;
    padding: 0.125rem 1rem;
    margin-right: 0;
  }

  &_size_x-small {
    font-size: $font-size-xs;
    width: auto;
    padding: 0.125rem 0.35rem;
    margin-right: 0;

    i {
      width: auto !important;
      margin: 0 !important;

      svg {
        height: 0.8rem !important;
        width: 0.5rem !important;
      }
    }
  }

  &_disabled {
    background-color: $casio-light-grey;
    color: $casio-dark-grey;
  }

  &_cplTestPeriod {
    background-color: rgba($casio-white, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: normal;
  }

  &_secondary {
    background-color: $casio-blue-5;
    color: $casio-blue-2;
    font-weight: 500;
    display: flex;
    align-items: center;
    align-content: center;
    height: 1.5rem;
    flex-basis: content;

    i {
      flex-basis: content;
      filter: invert(28%) sepia(89%) saturate(670%) hue-rotate(192deg)
        brightness(93%) contrast(88%);
      height: 100%;
      width: 100%;
      padding: 0.35rem;
      display: flex;

      svg {
        flex-basis: content;
        height: 100%;
        width: 100%;
      }

      svg:first-of-type {
        margin-right: 0.25rem;
      }
    }

    span {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: $font-size-xs;
    }
  }

  i {
    margin-right: 0.3rem;
  }
}
