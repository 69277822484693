@import "../../assets/variables";
@import "../../assets/mixins";

.userProfile {
  &__badge {
    font-size: $font-size-l;
    margin-right: 1rem;
  }

  &__lmsHint {
    display: flex;
    align-items: center;
    p {
      font-size: $font-size-s;
      padding-left: 1rem;
    }
    &__badge {
      height: 1.75rem;
    }
    &__eduplacesBadge {
      height: 2.8rem;
    }
  }

  &__nickname {
    font-weight: 700;
  }
  &__name {
    font-size: $font-size-s;
  }

  &__nameHeader {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    ul {
      margin-bottom: 1rem;
      font-size: $font-size-s;
      font-weight: 450;
    }

    &__description {
      font-size: $font-size-s;
      margin-bottom: 1rem;
      font-weight: 400;
    }

    &__buttons {
      justify-content: space-between;
      display: flex;
    }

    &__tis {
      font-size: $font-size-s;
      margin-bottom: 1rem;
      line-height: 1.5;
      font-weight: 400;
      > label > span:last-child {
        margin-top: 0;
      }
    }
  }

  &__accountSettings {
    display: flex;
    flex-direction: column;
    &__item {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > div {
        margin-bottom: 1rem;

        h4 {
          margin-bottom: 0.5rem;
        }
        p {
          font-size: $font-size-s;
        }
      }

      a,
      button {
        flex-shrink: 0;
      }
    }

    &__inform {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > div {
        margin-bottom: 0.5rem;

        p {
          font-size: $font-size-s;
        }
      }
    }
  }
  &__teacherInfoServiceLMS {
    &__header {
      font-weight: 600;
      margin-bottom: 0.5rem;
      font-size: $font-size-m;
    }
    &__info {
      font-size: $font-size-s;
      margin-bottom: 1rem;
    }
  }
  &__teacherInfoService {
    margin-top: 2rem;
    &__info {
      font-size: $font-size-s;
    }
    &__boxes {
      margin: 1rem 0 1rem 0;
      & .indented {
        padding-top: 1rem;
        @include mq-sm-min {
          padding-left: 2rem;
        }
      }
    }
    &__email {
      margin: 1rem 0 1rem 0;
    }
  }
}

.licenseRedeemGridItem {
  margin-bottom: 1rem;
}

@include mq-md-min {
  .userProfile__accountSettings__item {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > div {
      margin-right: 2rem;
    }
  }

  .licenseRedeemGridItem {
    margin-bottom: 0;
  }
}
